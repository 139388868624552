<template>
    
</template>

<script>
import {serverIp} from "../../public/config";

export default {
    name: "Claims",
    data() {
        return {
            serverIp: serverIp,
            tableData: [],
            total: 0,
            pageNum: 1,
            pageSize: 10,
            name: "",
            form: {},
            dialogFormVisible: false,
            multipleSelection: [],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
        }
    },
    created() {
        this.load()
    },
    methods: {
        changeState(row, state) {
            this.form = JSON.parse(JSON.stringify(row))
            this.form.state = state;
            this.save();
        },
        load() {
            this.request.get("/claims/page", {
                params: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    name: this.name,
                }
            }).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        save() {
            this.request.post("/claims", this.form).then(res => {
                if (res.code === '200') {
                    this.$message.success("保存成功")
                    this.dialogFormVisible = false
                    this.load()
                } else {
                    this.$message.error("保存失败")
                }
            })
        },
        handleAdd() {
            this.dialogFormVisible = true
            this.form = {}
            this.$nextTick(() => {
                if(this.$refs.img) {
                    this.$refs.img.clearFiles();
                }
                if(this.$refs.file) {
                    this.$refs.file.clearFiles();
                }
            })
        },
        handleEdit(row) {
            this.form = JSON.parse(JSON.stringify(row))
            this.dialogFormVisible = true
            this.$nextTick(() => {
                if(this.$refs.img) {
                    this.$refs.img.clearFiles();
                }
                if(this.$refs.file) {
                    this.$refs.file.clearFiles();
                }
            })
        },
        del(id) {
            this.request.delete("/claims/" + id).then(res => {
                if (res.code === '200') {
                    this.$message.success("删除成功")
                    this.load()
                } else {
                    this.$message.error("删除失败")
                }
            })
        },
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val
        },
        delBatch() {
            if (!this.multipleSelection.length) {
                this.$message.error("请选择需要删除的数据")
                return
            }
            let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
            this.request.post("/claims/del/batch", ids).then(res => {
                if (res.code === '200') {
                    this.$message.success("批量删除成功")
                    this.load()
                } else {
                    this.$message.error("批量删除失败")
                }
            })
        },
        reset() {
            this.name = ""
            this.load()
        },
        handleSizeChange(pageSize) {
            console.log(pageSize)
            this.pageSize = pageSize
            this.load()
        },
        handleCurrentChange(pageNum) {
            console.log(pageNum)
            this.pageNum = pageNum
            this.load()
        },
        handleFileUploadSuccess(res) {
            this.form.file = res
        },
        handleImgUploadSuccess(res) {
            this.form.img = res
        },
        download(url) {
            window.open(url)
        },
        exp() {
            window.open(`http://${serverIp}:8080/claims/export`)
        },
        handleExcelImportSuccess() {
            this.$message.success("导入成功")
            this.load()
        }
    }
}
</script>


<style>
</style>
